import { RoomType, Message, RoomMemberType } from '../../pages/Messenger/types';
import { CHANNEL_TYPE } from '../../utils/enums';
import {
  NEW_MESSAGE,
  SET_ACTIVE_ROOM_ID,
  SET_ROOMS,
  SET_MESSAGES,
  SET_ERROR,
  SET_ADD_NEW_CHANNEL_MODAL,
  SET_ROOM_MEMBERS,
  IS_ADDED_NEW_MEMBER,
  REMOVE_MESSAGE,
  UPDATE_MESSAGE,
  CLEAR_STATE,
  SET_ADD_MEMBERS_MODAL as SET_TYPE_CHANNEL_ADD_MEMBERS,
  SET_OPEN_ADD_MEMBERS_MODAL,
  CLEAR_TYPE_CHANNEL_ADD_MEMBERS,
  initIsAddMembersModal,
  ADD_PHOTOPIC,
} from './constants';
import { ActionTypes, MessengerState } from './types';

// Action creator
export const setActiveRoomId = (activeRoomId: number | null): ActionTypes => ({
  type: SET_ACTIVE_ROOM_ID,
  payload: activeRoomId,
});
export const setRooms = (rooms: RoomType[]): ActionTypes => ({
  type: SET_ROOMS,
  payload: rooms,
});
export const setMessages = (messages: Message[]): ActionTypes => ({
  type: SET_MESSAGES,
  payload: messages,
});
export const addNewMessage = (message: Message): ActionTypes => ({
  type: NEW_MESSAGE,
  payload: message,
});
export const removeMessage = (messageId: number): ActionTypes => ({
  type: REMOVE_MESSAGE,
  payload: messageId,
});
export const updateMessage = (message: Message): ActionTypes => ({
  type: UPDATE_MESSAGE,
  payload: message,
});
export const setMessengerError = (error: string): ActionTypes => ({
  type: SET_ERROR,
  payload: error,
});
export const setAddNewChannelModal = (type: CHANNEL_TYPE | ''): ActionTypes => ({
  type: SET_ADD_NEW_CHANNEL_MODAL,
  payload: type,
});
export const setTypeChannelAddMembers = (data: CHANNEL_TYPE | null): ActionTypes => ({
  type: SET_TYPE_CHANNEL_ADD_MEMBERS,
  payload: data,
});
export const setOpenAddMembersModal = (data: boolean): ActionTypes => ({
  type: SET_OPEN_ADD_MEMBERS_MODAL,
  payload: data,
});
export const clearAddMembersState = (): ActionTypes => ({
  type: CLEAR_TYPE_CHANNEL_ADD_MEMBERS,
  payload: initIsAddMembersModal,
});
export const setRoomMembers = (members: RoomMemberType[]): ActionTypes => ({
  type: SET_ROOM_MEMBERS,
  payload: members,
});
export const setAddNewMemberOpen = (data: boolean): ActionTypes => ({
  type: IS_ADDED_NEW_MEMBER,
  payload: data,
});
export const clearMessengerState = (): { type: string } => ({
  type: CLEAR_STATE,
});
export const addAvatarPicToAvatarBank = (photoPic: {
  key: number;
  value: string;
}): ActionTypes => ({
  type: ADD_PHOTOPIC,
  payload: photoPic,
});

// Reducer
const init: MessengerState = {
  activeRoomId: null,
  activeRoom: null,
  rooms: [],
  messages: [],
  error: '',
  isAddNewChannelModal: '',
  isAddMembersModal: initIsAddMembersModal,
  isAddNewMemberOpen: false,
  roomMembers: [],
  numberOfUnreadMessages: 0,
  newUnreadMessagesInClientState: [],
  listPatientIDsWithWhomHcpHasChannel: [],
  avatarBank: null,
};

export const messengerReduser = (state = init, { type, payload }: ActionTypes): MessengerState => {
  switch (type) {
    case ADD_PHOTOPIC:
      return {
        ...state,
        avatarBank: {
          ...state.avatarBank,
          [payload.key]: payload.value,
        },
      };
    case SET_ACTIVE_ROOM_ID:
      return {
        ...state,
        activeRoomId: payload,
      };
    case SET_ROOMS: {
      let numberOfUnreadMessages = 0;
      let activeRoomId = null;
      let activeRoom = null;
      const listPatientIDsWithWhomHcpHasChannel: any = [];
      if (payload.length) {
        payload.map((room: RoomType) => {
          if (room.isActiveRoom) {
            activeRoomId = room.id;
            activeRoom = room;
          }
          if (room.numberOfUnreadMessages) {
            numberOfUnreadMessages += room.numberOfUnreadMessages;
          }
          if (room.type === CHANNEL_TYPE.GROUP) {
            listPatientIDsWithWhomHcpHasChannel.push(room.userId);
          }
          return null;
        });
      }
      return {
        ...state,
        rooms: payload,
        activeRoomId,
        activeRoom,
        numberOfUnreadMessages,
        newUnreadMessagesInClientState: [],
        listPatientIDsWithWhomHcpHasChannel,
      };
    }
    case SET_MESSAGES:
      return {
        ...state,
        messages: payload,
      };
    case NEW_MESSAGE: {
      if (payload.roomId === state.activeRoomId) {
        return {
          ...state,
          messages: [...state.messages, payload],
        };
      }
      // Count unread messages
      let { numberOfUnreadMessages } = state;
      const updetedRooms = state.rooms.map((room: RoomType) => {
        if (room.id === payload.roomId) {
          const newRoom = { ...room };
          newRoom.isUnreadMessages = true;
          numberOfUnreadMessages += 1;
          newRoom.numberOfUnreadMessages = newRoom.numberOfUnreadMessages
            ? newRoom.numberOfUnreadMessages + 1
            : 1;
          return newRoom;
        }
        return room;
      });
      return {
        ...state,
        numberOfUnreadMessages,
        rooms: updetedRooms,
      };
    }
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((message: Message) => message.id !== payload),
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        messages: state.messages.map((message: Message) => {
          if (message.id === payload.id) {
            return payload;
          }
          return message;
        }),
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_ADD_NEW_CHANNEL_MODAL:
      return {
        ...state,
        isAddNewChannelModal: payload,
      };
    case SET_TYPE_CHANNEL_ADD_MEMBERS:
      return {
        ...state,
        isAddMembersModal: { type: payload, isOpen: state.isAddMembersModal.isOpen },
      };
    case SET_OPEN_ADD_MEMBERS_MODAL:
      return {
        ...state,
        isAddMembersModal: { type: state.isAddMembersModal.type, isOpen: payload },
      };
    case CLEAR_TYPE_CHANNEL_ADD_MEMBERS:
      return {
        ...state,
        isAddMembersModal: { type: null, isOpen: false },
      };
    case SET_ROOM_MEMBERS:
      return {
        ...state,
        roomMembers: payload,
      };
    case IS_ADDED_NEW_MEMBER:
      return {
        ...state,
        isAddNewMemberOpen: payload,
      };
    case CLEAR_STATE:
      return {
        ...init,
      };
    default:
      return state;
  }
};
