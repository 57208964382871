export const SET_ACTIVE_ROOM_ID = 'SET_ACTIVE_ROOM_ID';
export const SET_ROOMS = 'SET_ROOMS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const SET_ERROR = 'SET_ERROR';
export const SET_ADD_NEW_CHANNEL_MODAL = 'SET_ADD_NEW_CHANNEL_MODAL';
export const SET_ROOM_MEMBERS = 'SET_ROOM_MEMBERS';
export const IS_ADDED_NEW_MEMBER = 'IS_ADDED_NEW_MEMBER';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_ADD_MEMBERS_MODAL = 'SET_ADD_MEMBERS_MODAL';
export const SET_OPEN_ADD_MEMBERS_MODAL = 'SET_OPEN_ADD_MEMBERS_MODAL';
export const CLEAR_TYPE_CHANNEL_ADD_MEMBERS = 'CLEAR_TYPE_CHANNEL_ADD_MEMBERS';
export const ADD_PHOTOPIC = 'ADD_PHOTOPIC';

export const initIsAddMembersModal = { type: null, isOpen: false };
