import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Popover, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';
import { ChannelItemProps, MemberItemProps } from '../../../pages/Messenger/types';
import { getCurrenLang, getHcpName, getMessengerState, GetState } from '../../../redux/selector';
import { MessengerState } from '../../../redux/messenger/types';
import useChat from '../../../hooks/useChat';
import ItemAvatar from '../ItemAvatar/ItemAvatar';
import { CHANNEL_TYPE } from '../../../utils/enums';
import { WarnNotifModal } from '../../../common/NotificationModal';
import { useSocket } from '../../../ SocketContext';
import { useQueryHcpPic } from '../../../graphql/hcpProfile';
import { addAvatarPicToAvatarBank } from '../../../redux/messenger';

export const ChannelItem = ({ room, isShowLabel, closeMenu }: ChannelItemProps): ReactElement => {
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoomId } = messengerState;
  const { socket }: any = useSocket();
  const { changeActiveRoom } = useChat(socket);
  const { patientName, patientEmail, patientInitials } = useMemo(() => {
    const nameParts = room.name.trim().split('|');
    const name = nameParts?.[0];
    const email = nameParts?.[1];
    // Split the name by spaces
    const patientNameSplited = name.trim().split(/[\s-]/);
    let initials = '';
    // check every word in the first and last name
    patientNameSplited.forEach((part: any) => {
      // Add the first letter of each word to the initials
      initials += part.charAt(0).toUpperCase();
    });

    return { patientName: name?.trim(), patientEmail: email?.trim(), patientInitials: initials };
  }, [room.name]);

  const changeRoom = (): void => {
    if (activeRoomId) {
      const request = {
        previousRoomId: activeRoomId,
        nextRoomId: room.id,
      };
      changeActiveRoom(request);
    }
    closeMenu();
  };

  return (
    <Tooltip
      placement="right"
      title={!isShowLabel && room.name ? `${patientName} ${patientEmail}` : ''}
    >
      <li className={styles['channel-item__li']} onClick={changeRoom} aria-hidden>
        <div
          className={cx({
            [styles['channel-item__div']]: true,
            [styles.active]: room.isActiveRoom,
          })}
        >
          <div className={styles['channel-item__icon']}>#</div>
          <div className={styles['channel-item__name-container']}>
            <div className={styles['channel-item__name']}>
              {isShowLabel ? patientName : patientInitials}
            </div>
            {isShowLabel && <div className={styles['channel-item__email']}>{patientEmail}</div>}
          </div>
        </div>
      </li>
    </Tooltip>
  );
};

export const DirectItem = ({ room, isShowLabel, closeMenu }: ChannelItemProps): ReactElement => {
  const hcp = useSelector((state: GetState) => getHcpName(state));
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoomId } = messengerState;
  const { socket }: any = useSocket();
  const { changeActiveRoom } = useChat(socket);
  let numberOfUnreadMessages: number | string = 0;
  if (room.numberOfUnreadMessages) {
    numberOfUnreadMessages = room.numberOfUnreadMessages > 9 ? '9+' : room.numberOfUnreadMessages;
  }

  const { _getHcpPic, hcpPic } = useQueryHcpPic();
  const photoUuid = room?.photo;
  const dispatch = useDispatch();

  useEffect(() => {
    if (photoUuid) {
      _getHcpPic(photoUuid);
    }
  }, [photoUuid]);

  useEffect(() => {
    if (hcpPic) {
      dispatch(addAvatarPicToAvatarBank({ key: room.hcpId || 0, value: hcpPic }));
      dispatch(addAvatarPicToAvatarBank({ key: hcp.id || 0, value: hcp.photoPic || '' }));
    }
  }, [hcpPic]);

  const changeRoom = (): void => {
    if (activeRoomId) {
      const request = {
        previousRoomId: activeRoomId,
        nextRoomId: room.id,
      };
      changeActiveRoom(request);
    }
    closeMenu();
  };

  return (
    <Tooltip placement="right" title={!isShowLabel ? room.name : ''}>
      <li className={styles['channel-item__li']} onClick={changeRoom} aria-hidden>
        <div
          className={cx({
            [styles['direct-item__div']]: true,
            [styles.active]: room.isActiveRoom,
          })}
        >
          <ItemAvatar room={room} isShowStatus />
          {isShowLabel && (
            <div className={styles['direct-item__name-container']}>
              <div className={styles['msg__room-name']}>{room.name}</div>
              <div className={styles['msg__room-hospital']}>{room.profession}</div>
              <div className={styles['msg__room-hospital']}>{room.organisation}</div>
            </div>
          )}
        </div>
        {room.isUnreadMessages && (
          <div className={styles.msg__unread}>{numberOfUnreadMessages}</div>
        )}
      </li>
    </Tooltip>
  );
};

export const MemberItem = ({ member }: MemberItemProps): ReactElement => {
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const remove_from_channel = t?.chat?.remove_from_channel || 'Remove from Channel';
  const direct_message = t?.chat?.direct_message || 'Direct message';
  const are_you_sure_remove_from_channel =
    t?.chat?.are_you_sure_remove_from_channel ||
    'Are you sure you want to remove this member from the channel?';
  const member_will_notified =
    t?.chat?.member_will_notified || 'The member will be notified on the dashboard.';

  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoomId } = messengerState;
  const { socket }: any = useSocket();
  const { removeMemberFromRoom, createRoom } = useChat(socket);
  const hcp = useSelector((state: GetState) => getHcpName(state));

  const { _getHcpPic, hcpPic } = useQueryHcpPic();
  const photoUuid = member?.photo;
  const dispatch = useDispatch();

  useEffect(() => {
    if (photoUuid) {
      _getHcpPic(photoUuid);
    }
  }, [photoUuid]);

  useEffect(() => {
    if (hcpPic) {
      dispatch(addAvatarPicToAvatarBank({ key: member.hcpId, value: hcpPic }));
    }
  }, [hcpPic]);

  // Local state
  const [isWarningModal, setWarningModal] = useState(false);

  // Remove member from channel
  const removeFromChannel = (): void => {
    if (activeRoomId) {
      const request = {
        roomId: activeRoomId,
        hcpId: member.hcpId,
      };
      removeMemberFromRoom(request);
    }
    setWarningModal(false);
  };

  // Redirect to direct channel
  const createDirectMessage = (): void => {
    if (activeRoomId) {
      const request = {
        userId: member.hcpId,
        name: member.name,
        type: CHANNEL_TYPE.DIRECT,
      };
      createRoom(request, activeRoomId);
    }
  };

  // JSX
  const popoverContent = (
    <div className={styles['member-item__popover-container']}>
      <div
        key={`popoverRemoveFromChannel${member.hcpId}`}
        className={styles['member-item__popover-row']}
        onClick={(): void => setWarningModal(true)}
        aria-hidden
      >
        {remove_from_channel}
      </div>
      <div
        key={`popoverRemoveDirectMsg${member.hcpId}`}
        className={styles['member-item__popover-row']}
        onClick={createDirectMessage}
        aria-hidden
      >
        {direct_message}
      </div>
    </div>
  );

  const warnContent = (
    <>
      <div className={styles['member-item__warning-content']}>
        {are_you_sure_remove_from_channel}
      </div>
      <div className={styles['member-item__warning-content']}>{member_will_notified}</div>
    </>
  );

  const isMyAvatar = member.hcpId === hcp.id;

  return (
    <div className={styles['channel-item__li']}>
      <div
        className={cx({
          [styles['member-item__div']]: true,
        })}
      >
        <ItemAvatar
          room={{
            name: member.name,
            type: CHANNEL_TYPE.DIRECT,
            id: member.hcpId,
            isActiveRoom: false,
            numberOfUnreadMessages: null,
            isUnreadMessages: false,
            isOnline: false,
            profession: '',
            organisation: '',
            photo: isMyAvatar ? hcp.photoPic : member.photo,
            userId: 0,
          }}
          isShowStatus
        />
        <div className={styles['direct-item__name-container']}>
          <div className={styles['msg__room-name']}>{member.name}</div>
          <div className={styles['msg__room-hospital']}>{member.profession}</div>
          <div className={styles['msg__room-hospital']}>{member.organisation}</div>
        </div>
        <Popover
          placement="bottomRight"
          content={popoverContent}
          trigger="click"
          overlayClassName={styles['member-item__popover']}
        >
          <div className={styles['member-item__dots']}>
            <div className={styles['member-item__dot']} />
            <div className={styles['member-item__dot']} />
            <div className={styles['member-item__dot']} />
          </div>
        </Popover>
      </div>

      {/* Popups */}
      {isWarningModal && (
        <WarnNotifModal
          onClose={(): void => setWarningModal(false)}
          content={warnContent}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={removeFromChannel}
        />
      )}
    </div>
  );
};
